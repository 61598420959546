import { api, createCrudService, metadataHelper } from '@cargill/shared'
import { createCrudColumns } from '@brain/core'

const baseURL = '/api/weeklyTabularReportsCostProjection'

const service = {}

let updatedMetadata = null

const getUrl = (endpoint) => {
  endpoint = endpoint[0].toUpperCase() + endpoint.slice(1) //capitallize first letter

  return `${baseURL}${endpoint}`
}

const buildMetadata = async (service) => {
  let metadata = await service.getMeta()

  return await metadataHelper.buildMetadata(metadata)
}

service.getMeta = async (endpoint, translate) => {
  const url = getUrl(endpoint)
  const service = createCrudService(url, api)

  updatedMetadata = await buildMetadata(service)
  metadataHelper.setupLazyLoadGridFilters(updatedMetadata, service)
  return createCrudColumns(updatedMetadata, 'serverSide', translate)
}

service.getItemsCount = async (_baseFilter, endpoint) => {
  const url = getUrl(endpoint)

  try {
    const service = createCrudService(url, api)
    return await service.getCountVm(_baseFilter)
  } catch (err) {
    console.error(`Failed to get ${endpoint}Count`, err)
  }
}

service.getItems = async (_baseFilter, endpoint) => {
  const url = getUrl(endpoint)

  try {
    const service = createCrudService(url, api)
    const data = await service.getAllVm(_baseFilter)
    await metadataHelper.getPostProcessing(data, updatedMetadata)
    return data
  } catch (err) {
    console.error(`Failed to get ${url}`, err)
  }
}

service.export = async (_baseFilter, endpoint) => {
  const url = getUrl(endpoint)
  const service = createCrudService(url, api)
  return await service.export(_baseFilter)
}

export default service
