<template>
  <div class="grid-wrapper">
    <div class="grid-wrapper-label">
      <div>{{ translate('title') }}</div>
      <div class="import-export-container">
        <div>
          <bc-btn
            color="link-gray"
            @click="doExport"
            :title="$t('controlTower.pages.stuffingPortal.export')"
            id="btn-activator-export"
          >
            {{ $t('controlTower.pages.stuffingPortal.export') }}
          </bc-btn>
        </div>
      </div>
    </div>

    <cargill-color-tabs
      :tabs="tabs"
      :tabsReady="tabsReady"
      :reloadCurrentTab="loadTab"
    >
      <template v-slot="{ item, index }">
        <div>
          <div class="renderer-wrapper">
            <bc-grid
              :ref="`gridComponent-${index}`"
              :gridOptions="gridOptions"
              :columnDefs="item.columnDefs"
              :getItems="item.getItems"
              :countItems="item.countItems"
              :style="`height: 650px; width: 100%`"
              layer="3"
              :selectable="false"
              :pageSize="13"
              :customSetSize="customSetSize"
              mode="serverSide"
              @grid-ready="onGridReady"
              @reload-table="loadTab"
            />
          </div>
        </div>
      </template>
    </cargill-color-tabs>
  </div>
</template>

<script>
import service from '../../api/weeklyTabularReportsCostProjectionService'
import { BcGrid } from '@brain/core'
import { CargillColorTabs } from '@cargill/shared'
import { defaultGridOptions, gridUtils } from '@cargill/shared'
import FileDownload from 'js-file-download'

export default {
  components: { CargillColorTabs, BcGrid },
  name: 'WeeklyTabularReportsCostProjection',
  data() {
    return {
      tabsReady: false,
      tab: 0,
      crudService: service,
      tabsToCreate: ['logistics', 'storage', 'stuffing', 'maritimeBooking'],
      tabs: [],
      filter: {},
      gridOptions: {
        ...defaultGridOptions,
        ...gridUtils.defaultGridOptions,
        getRowHeight: 300,
        rowModelType: 'serverSide'
      }
    }
  },
  async created() {
    this.tabs = await Promise.all(
      this.tabsToCreate.map((tab, index) => this.createTab(tab, index))
    )
  },
  computed: {
    currentTab() {
      return this.tabs.find((tab) => tab.index == this.tab)
    }
  },
  methods: {
    createTab(key, index) {
      return service.getMeta(key, this.translate).then((col) => {
        return {
          title: this.translate(key),
          tabColor: this.tabColor(key),
          name: key,
          index,
          columnDefs: col,
          getItems: (filter) => {
            this.filter = filter
            return service.getItems(filter, key)
          },
          countItems: (filter) => service.getItemsCount(filter, key),
          export: (filter) => service.export(filter, key)
        }
      })
    },
    tabColor(key) {
      let colors = {
        logistics: 'blue',
        storage: 'yellow',
        stuffing: 'green',
        maritimeBooking: 'red'
      }

      return colors[key]
    },
    translate(key) {
      return this.$t(
        `application.pages.weeklyTabularReportsCostProjection.${key}`
      )
    },
    loadTab(index) {
      const gridComponent = this.$refs[`gridComponent-${index}`]
      gridComponent?.loadData()
      this.filter = {}
      this.tab = index
    },
    customSetSize(aggridOptions) {
      if (aggridOptions) {
        gridUtils.resize(aggridOptions)
      }
    },
    onGridReady(params) {
      console.log({ params })
    },
    async doExport() {
      const result = await this.currentTab?.export(this.filter)
      const label = this.translate(this.currentTab?.name)

      FileDownload(result.data, `${label}.xlsx`)
    }
  },
  watch: {
    tabs() {
      this.tabsReady = true
    }
  },
  provide() {
    return {
      validationResult: {}
    }
  }
}
</script>

<style lang="scss">
.grid-wrapper {
  margin: 1rem;
  background-color: #232834;
  .grid-wrapper-label {
    color: var(--bc-title-color);
    font-family: var(--bc-font-regular);
    font-weight: 900;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-color: #232834;
    margin-top: 1.3rem;
    align-items: center;
    margin: 0 1rem;
  }
  .import-export-container {
    display: flex;
    justify-content: space-between;
  }
}

.renderer-wrapper {
  height: 100%;
  padding: 10px 5px;
  margin: 5px 0 0 40px;
}
</style>
